import axios, { AxiosError } from "axios";
import { HttpError } from "react-admin";
import { BlogArticle } from "../types/blogArticle";
import { FileUploadResponse } from "../types/file";
import {
  AcceptResponse,
  LoginResponse,
  RegisterResponse,
  User,
  UserCreateData,
  UserCreateResponse,
  UserLoginData,
  UserRegistrationData,
} from "../types/auth";
import {
  Banner,
  BannerResponse,
  DeleteBannerResponse,
  HomeOrderResponse,
} from "../types/banner";
import { Thumb, ThumbResponse } from "../types/thumb";
import { Event, EventEnrollment } from "../types/events";

// export const API_URL = "https://backend.br1.afrac.app";
export const API_URL = "https://app.afrac.com.br";
// export const API_URL = "https://afrac-staging.us4.litegps.app";
// export const API_URL = "http://localhost:1337";

const httpClient = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

type ErrorResponse = {
  status: number;
  message: string;
};

const getToken = (): string => JSON.parse(localStorage.getItem("auth")!)?.token;

export const getNewsletters = async (): Promise<
  { email: string; id: string }[]
> => {
  try {
    const response = await httpClient.get("/api/newsletter", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw new HttpError(
      response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getBlogArticles = async (): Promise<BlogArticle[]> => {
  try {
    const response = await httpClient.get("/api/blog");
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw new HttpError(
      response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getPaymentMethods = async (): Promise<BlogArticle[]> => {
  try {
    const response = await httpClient.get("/api/meiospagamento");
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getVitrineArticles = async (): Promise<BlogArticle[]> => {
  try {
    const response = await httpClient.get("/api/vitrine");
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const uploadFile = async (file: File): Promise<FileUploadResponse> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await httpClient.post("/api/file/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const createUser = async (
  userData: UserCreateData,
): Promise<UserCreateResponse> => {
  try {
    const response = await httpClient.post("/api/user/new", userData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const registerUser = async (
  userData: UserRegistrationData,
): Promise<RegisterResponse> => {
  try {
    const response = await httpClient.post("/api/auth/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const loginUser = async (
  loginData: UserLoginData,
): Promise<LoginResponse> => {
  try {
    const response = await httpClient.post("/api/auth/login", loginData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const changeUserRole = async (
  email: string,
  role: "admin" | "user",
): Promise<{ success: boolean }> => {
  try {
    const response = await httpClient.patch(
      "/api/user/role",
      { email, role },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const acceptUser = async (email: string): Promise<AcceptResponse> => {
  try {
    const response = await httpClient.post(
      `/api/auth/accept/${email}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const revokeUser = async (email: string): Promise<AcceptResponse> => {
  try {
    const response = await httpClient.post(
      `/api/auth/revoke/${email}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const enableUserEvent = async (
  email: string,
): Promise<AcceptResponse> => {
  try {
    const response = await httpClient.post(
      `/api/user/enable-event/${email}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const disableUserEvent = async (
  email: string,
): Promise<AcceptResponse> => {
  try {
    const response = await httpClient.post(
      `/api/user/disable-event/${email}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getHomeOrder = async (): Promise<HomeOrderResponse> => {
  try {
    const response = await httpClient.get("/api/homeorder");
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const replaceHomeOrder = async (
  order: Omit<HomeOrderResponse["order"], "_id">,
): Promise<HomeOrderResponse> => {
  try {
    const response = await httpClient.post(
      "/api/homeorder/replace",
      { order },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getBanner = async (): Promise<BannerResponse> => {
  try {
    const response = await httpClient.get("/api/banner");
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const replaceBanner = async (bannerData: Banner): Promise<Banner> => {
  try {
    const response = await httpClient.post("/api/banner/replace", bannerData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const deleteBanner = async (): Promise<DeleteBannerResponse> => {
  try {
    const response = await httpClient.delete("/api/banner/delete", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getUser = async (): Promise<User> => {
  try {
    const response = await httpClient.get("/api/user/me", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getUsers = async (): Promise<User[]> => {
  try {
    const response = await httpClient.get("/api/user", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getEvents = async (): Promise<(Event & { _id: string })[]> => {
  try {
    const response = await httpClient.get("/api/events", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getEventAttendees = async (): Promise<
  (EventEnrollment & { _id: string })[]
> => {
  try {
    const response = await httpClient.get("/api/events/enrollments");
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const createEvent = async (
  event: Event,
): Promise<Event & { _id: string }> => {
  try {
    const response = await httpClient.post("/api/events", event, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const editEvent = async (
  id: string,
  event: Partial<Event>,
): Promise<Event & { _id: string }> => {
  try {
    const response = await httpClient.put("/api/events/edit/" + id, event, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getThumbs = async (): Promise<Thumb[]> => {
  try {
    const response = await httpClient.get("/api/thumb");
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const createThumb = async (
  image: string,
  link: string,
): Promise<ThumbResponse> => {
  try {
    const response = await httpClient.post(
      "/api/thumb/new",
      {
        image,
        link,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const deleteThumb = async (
  thumbId: string,
): Promise<{ success: boolean }> => {
  try {
    const response = await httpClient.delete(`/api/thumb/delete/${thumbId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};

export const getEventBriteEvent = async (
  token: string,
  eventId: string,
): Promise<any> => {
  try {
    const response = await httpClient.get("/api/eventbrite/event/" + eventId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    const errorResponse = response?.data as ErrorResponse;
    throw new HttpError(
      errorResponse?.message || response?.statusText || "",
      response?.status || 0,
      response?.data,
    );
  }
};
